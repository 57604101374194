import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-fort-wayne-indiana.png'
import image0 from "../../images/cities/scale-model-of-foellinger-freimann-botanical-conservatory-in-fort-wayne-indiana.png"
import image1 from "../../images/cities/scale-model-of-mengerson-nature-preserve-in-fort-wayne-indiana.png"
import image2 from "../../images/cities/scale-model-of-history-center-in-fort-wayne-indiana.png"
import image3 from "../../images/cities/scale-model-of-fort-wayne-firefighters-museum-in-fort-wayne-indiana.png"
import image4 from "../../images/cities/scale-model-of-fort-wayne-museum-of-art-in-fort-wayne-indiana.png"
import image5 from "../../images/cities/scale-model-of-visit-fort-wayne-in-fort-wayne-indiana.png"
import image6 from "../../images/cities/scale-model-of-waynedale.com-in-fort-wayne-indiana.png"
import image7 from "../../images/cities/scale-model-of-emage-vision-center-in-fort-wayne-indiana.png"
import image8 from "../../images/cities/scale-model-of-coral-reef-aquarium-in-fort-wayne-indiana.png"
import image9 from "../../images/cities/scale-model-of-fort-wayne-children's-zoo-in-fort-wayne-indiana.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Fort Wayne'
            state='Indiana'
            image={image}
            lat='41.0882'
            lon='-85.1439'
            attractions={ [{"name": "Foellinger-Freimann Botanical Conservatory", "vicinity": "1100 S Calhoun St, Fort Wayne", "types": ["park", "store", "point_of_interest", "establishment"], "lat": 41.0758517, "lng": -85.13909699999999}, {"name": "Mengerson Nature Preserve", "vicinity": "5895 Stellhorn Rd, Fort Wayne", "types": ["park", "point_of_interest", "establishment"], "lat": 41.1213679, "lng": -85.06788970000002}, {"name": "History Center", "vicinity": "302 E Berry St, Fort Wayne", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.0795036, "lng": -85.13628589999996}, {"name": "Fort Wayne Firefighters Museum", "vicinity": "226 W Washington Blvd, Fort Wayne", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.0769952, "lng": -85.1417543}, {"name": "Fort Wayne Museum of Art", "vicinity": "311 E Main St, Fort Wayne", "types": ["museum", "point_of_interest", "establishment"], "lat": 41.081253, "lng": -85.13606319999997}, {"name": "Visit Fort Wayne", "vicinity": "927 S Harrison St, Fort Wayne", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.0771848, "lng": -85.1404293}, {"name": "Waynedale.com", "vicinity": "2505 Lower Huntington Rd #3, Fort Wayne", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 41.02003800000001, "lng": -85.17073800000003}, {"name": "Emage Vision Center", "vicinity": "3030 Lake Ave, Fort Wayne", "types": ["travel_agency", "health", "point_of_interest", "establishment"], "lat": 41.0894509, "lng": -85.09956299999999}, {"name": "Coral Reef Aquarium", "vicinity": "3430 N Anthony Blvd, Fort Wayne", "types": ["aquarium", "pet_store", "store", "point_of_interest", "establishment"], "lat": 41.107715, "lng": -85.11524199999997}, {"name": "Fort Wayne Children's Zoo", "vicinity": "3411 Sherman Blvd, Fort Wayne", "types": ["zoo", "point_of_interest", "establishment"], "lat": 41.105573, "lng": -85.15425499999998}] }
            attractionImages={ {"Foellinger-Freimann Botanical Conservatory":image0,"Mengerson Nature Preserve":image1,"History Center":image2,"Fort Wayne Firefighters Museum":image3,"Fort Wayne Museum of Art":image4,"Visit Fort Wayne":image5,"Waynedale.com":image6,"Emage Vision Center":image7,"Coral Reef Aquarium":image8,"Fort Wayne Children's Zoo":image9,} }
       />);
  }
}